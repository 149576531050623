import {BowbEvent} from "../../../models/models";
import React, {useEffect, useState} from "react";
import "./playing.css"
import {useGameState} from "../../../hooks/use-game-state/use-game-state";
import useWebSocket from "react-use-websocket";
import FadeInElement from "../../../components/fade-element/fade-in-element";
import CommonTextbox from "../../../components/textbox/textbox";
import CommonBob from "../../../components/bob/bob";
import {EventType} from "../../../models/events";
import GangMemberChoice from "../../../components/gang-member-choice/gang-member-choice";
import FadeOutElement from "../../../components/fade-element/fade-out-element";
import Toaster, {ToastService} from "../../../services/toaster.service";

export default function Playing({webSocket}: { webSocket: ReturnType<typeof useWebSocket> }) {
    const [startFade, setStartFade] = useState(false);

    const {currentState, setCurrentState} = useGameState();
    const {lastJsonMessage} = webSocket;

    let event: BowbEvent = {type: "none", subType: "none", data: {}};
    useEffect(() => {
        event = lastJsonMessage as BowbEvent;

        let validLobby = event?.data.lobby_code === currentState.lobby.code;
        if (validLobby && event?.subType === EventType.ANSWER_CHOSEN) {
            // Update counter of players that have yet to choose
            setCurrentState(prevState => ({
                ...prevState,
                currentQuote: {
                    ...prevState.currentQuote,
                    playersToAnswer: currentState.currentQuote.playersToAnswer - 1
                }
            }));
        } else if (validLobby && event?.subType === EventType.SHOW_ANSWER) {
            setStartFade(true);

            setTimeout(() => {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {...prevState.host, state: 'Answer'},
                    currentQuote: {...prevState.currentQuote, playersToSip: event?.data.players_to_sip}
                }));
            }, 500);
        } else if (validLobby && event?.subType === EventType.LEFT_LOBBY) {
            // TODO: If another player joins during the game, they are in the waiting state. But that is not tracked in the backend
            //  So when they leave, they have not "alreadyAnswered" and the player to answer count drops when it shouldn't.
            //     - This can be fixed by changing the playersToAnswerProperty from a number to a list of names that have to answer.

            // If player already answered, don't subtract 1 from playersToAnswer. Otherwise, do.
            if (event.data.alreadyAnswered) {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {
                        ...prevState.host,
                        players: prevState.host.players.filter(player => player !== event.data.username)
                    },
                    currentQuote: {
                        ...prevState.currentQuote,
                        playersTotal: prevState.currentQuote.playersTotal - 1,
                        playersToSip: prevState.currentQuote.playersToSip.filter(player => player !== event.data.username)
                    }
                }));
            } else {
                setCurrentState(prevState => ({
                    ...prevState,
                    host: {
                        ...prevState.host,
                        players: prevState.host.players.filter(player => player !== event.data.username)
                    },
                    currentQuote: {
                        ...prevState.currentQuote,
                        playersToAnswer: prevState.currentQuote.playersToAnswer - 1,
                        playersTotal: prevState.currentQuote.playersTotal - 1,
                        playersToSip: prevState.currentQuote.playersToSip.filter(player => player !== event.data.username)
                    }
                }));
            }

            ToastService.error(`User ${event.data.username} disconnected and left the lobby.`);
        } else if (validLobby && event?.subType === EventType.USER_REJOIN) {
            const playerList = [...currentState.host.players];
            playerList.push(event?.data.username)

            setCurrentState(prevState => ({
                ...prevState,
                currentQuote: {
                    ...prevState.currentQuote,
                    playersTotal: ++prevState.currentQuote.playersTotal,
                },
                host: {
                    ...prevState.host,
                    players: playerList
                }
            }))

            ToastService.info(`User ${event.data.username} rejoined lobby.`);
        }
    }, [lastJsonMessage]);

    return (
        <FadeOutElement startFade={startFade}>
            <FadeInElement startFade={true}>
                <h1 className={"player-answer-count"}>
                    {currentState.currentQuote.playersToAnswer}
                    <br/>
                    {currentState.currentQuote.playersToAnswer === 1 ? "answer left" : "answers left"}
                </h1>

                <div className="container">
                    <div className={"borp"}>
                        <CommonTextbox text={currentState.currentQuote.question}></CommonTextbox>
                        <img className={"talking-stick"}
                             src={`${process.env.PUBLIC_URL}/assets/img/talking-shtick-2.png`} alt={"talking stick"}/>
                        <CommonBob></CommonBob>
                    </div>
                    <div className={"quote-container"}>
                        {currentState.currentQuote.quoteData.imageName === ""
                            ? <CommonTextbox text={currentState.currentQuote.quoteData.quoteText}/>
                            : <img className={"quote-image"}
                                   src={`./assets/img/quotes/${currentState.currentQuote.quoteData.imageName}.png`}
                                   alt={"quote"}/>
                        }
                    </div>
                    <GangMemberChoice></GangMemberChoice>
                </div>
            </FadeInElement>

            <Toaster/>
        </FadeOutElement>
    );
}
