import React, { FC } from "react";
import "./checkbox.css";

interface CustomCheckboxProps {
    checked: boolean;
    onChange: () => void;
    disabled?: boolean;
}

const CommonCheckbox: FC<CustomCheckboxProps> = ({ checked, onChange, disabled = false }) => {
    return (
        <label className={`custom-checkbox ${disabled ? "disabled" : ""}`}>
            <input type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
            <span className="checkmark">{checked ? "✔" : ""}</span>
            <span>Skip some older quotes</span>
        </label>
    );
};

export default CommonCheckbox;
